import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <Container fluid className="vh-100 text-center text-white">
      <Row className="h-100">
        <Col className="my-auto">
          <Row>
            <Col sm={12}>
              <h1 className="h3 text-light">ERROR 404 - PAGE NOT FOUND</h1>
            </Col>
            <Col sm={12}>
              <StaticImage
                // This is a presentational image, so the alt should be an empty string
                alt="404 Error poster"
                // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash
                src={"../images/404_error.png"}
                placeholder={"tracedSVG"}
                layout={"constrained"}
                width="700"
                formats={["auto", "webp", "avif"]}
              />
            </Col>
            <Col sm={12} className="text-info">
              <br />
              <p>Sorry the page you are trying to find is not available.</p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default NotFoundPage
